import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [clicked, setClickedMenu] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);

    const location = useLocation();

    // Toggle mobile menu
    const showMenu = () => {
        setClickedMenu(!clicked);
    };

    // Close menu when clicking a link
    const handleLink = () => {
        setClickedMenu(false);
        setOpenDropdown(null);
    };

    // Toggle dropdown menu
    const toggleDropdown = (menuName) => {
        setOpenDropdown(openDropdown === menuName ? null : menuName);
    };

    return (
        <>
            <header className={location.pathname === '/' ? "header-style1 menu_area-light fixedHeader" : "header-style1 menu_area-light fixedHeader scrollHeader"}>
                <div className="navbar-default">
                    <div className="container-fluid px-sm-2-9">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-12 px-0">
                                <div className="menu_area alt-font">
                                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                                        <div className="navbar-header navbar-header-custom px-3">
                                            <Link className="home-link navbar-brand logodefault position-relative"  to={'/'}>
                                                <div className='spira-logo'>
                                                    <div class="spiralContainer one">
                                                        <div class="spiral"> </div>
                                                    </div>
                                                    <div class="spiralContainer two">
                                                        <div class="spiral"> </div>
                                                    </div>
                                                    <div class="spiralContainer three">
                                                        <div class="spiral"> </div>
                                                    </div>
                                                </div>
                                                <img id="logo-img" height="100" width="200" class="img-fluid auto_size" src="img/logo.png" alt="logo-img" />
                                            </Link>
                                        </div>

                                        {/* Fixed Mobile Toggler */}
                                        <button className="navbar-toggler" type="button" onClick={showMenu}>
                                            <span className="navbar-toggler-icon"></span>
                                        </button>

                                        {/* Navbar Items */}
                                        <div className={`collapse navbar-collapse ${clicked ? 'show' : ''}`} id="navbarNav">
                                            <ul className="navbar-nav ms-auto">
                                                <li><Link to={'/'} onClick={handleLink}>Home</Link></li>

                                                {/* About Us Dropdown */}
                                                <li className={`dropdown ${openDropdown === 'About' ? 'open' : ''}`}>
                                                    <Link to={'/About'} onClick={(e) => { e.preventDefault(); toggleDropdown('About'); }}>
                                                        About Us <i className='fa fa-chevron-down'></i>
                                                    </Link>
                                                    {openDropdown === 'About' && (
                                                        <ul>
                                                            <li><Link onClick={handleLink} to={'/About'}>About Adventus</Link></li>
                                                            <li><Link onClick={handleLink} to={'https://adventusindia.com/img/pdf/adventus_profile.pdf'} target='_blank'>Company Profile</Link></li>
                                                            <li><Link onClick={handleLink} to={'/Testimonials'}>Client Testimonials</Link></li>
                                                        </ul>
                                                    )}
                                                </li>

                                                {/* Services Dropdown */}
                                                <li className={`dropdown ${openDropdown === 'Services' ? 'open' : ''}`}>
                                                    <Link to={'/Services'} onClick={(e) => { e.preventDefault(); toggleDropdown('Services'); }}>
                                                        Services <i className='fa fa-chevron-down'></i>
                                                    </Link>
                                                    {openDropdown === 'Services' && (
                                                        <ul>
                                                            <li><Link onClick={handleLink} to={'/SchoolManagementSoftware'}>School Management Software</Link></li>
                                                            <li><Link onClick={handleLink} to={'/SchoolMobileApp'}>School Mobile App</Link></li>
                                                            <li><Link onClick={handleLink} to={'/RFIDSolution'}>RFID Solution</Link></li>
                                                            <li><Link onClick={handleLink} to={'/WebDesigning'}>Website Designing</Link></li>
                                                            <li><Link onClick={handleLink} to={'/CustomSoftwareDevelopment'}>Custom Software Development</Link></li>
                                                            <li><Link onClick={handleLink} to={'/BulkSMS'}>Bulk SMS Integration</Link></li>
                                                            <li><Link onClick={handleLink} to={'/BiometricIntegration'}>Biometric Integration</Link></li>
                                                            <li><Link onClick={handleLink} to={'/PaymentGateway'}>Payment Gateway Integration</Link></li>
                                                            <li><Link onClick={handleLink} to={'/DomainRegistration'}>Domain Name Registration</Link></li>
                                                            <li><Link onClick={handleLink} to={'/WebHosting'}>Web Hosting</Link></li>
                                                            <li><Link onClick={handleLink} to={'/EmailHosting'}>Email Hosting</Link></li>
                                                        </ul>
                                                    )}
                                                </li>

                                                <li><Link to={'/SchoolERP'} onClick={handleLink}>School ERP</Link></li>
                                                <li><Link to={'/Clientele'} onClick={handleLink}>Clientele</Link></li>
                                                <li><Link to={'/ChannelPartner'} onClick={handleLink}>Channel Partner</Link></li>
                                                <li><Link to={'/Contact'} onClick={handleLink}>Contact Us</Link></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Navbar;
